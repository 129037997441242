import React from "react";
import style from "./homepage.module.scss";
import {
  attributeArr,
  projectsArr,
  serviceArr,
  socialArr,
} from "../../data/home";
import { contactNo, whatsappURL } from "../../helpers/constant-words";
import { images, showcasevideo } from "../../images";
import FilledDot from "../../components/FilledDot";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectCard from "../../components/ProjectCard/ProjectCard";

const Homepage = () => {
  const socialList = socialArr.map((item) => (
    <React.Fragment key={`social-${item.id}`}>
      <a
        href={item.url}
        id={item.id}
        target={item.external === 1 ? "_blank" : ""}
        rel="noreferrer"
      >
        <img src={item.icon} alt="" className={style.social_icon} />
      </a>
    </React.Fragment>
  ));

  const attributeList = attributeArr.map((item) => (
    <React.Fragment key={`feature-${item.id}`}>
      <div
        className="col-sm-6 col-md-6 col-lg-4"
        id={`attribute-${item.id}`}
        data-aos="fade-up"
      >
        <div
          className={style.attribute_card}
          style={{ background: `url(${item.bgImg})` }}
        >
          <div className={style.title_card}>
            <h2 className={style.title}>{item.title}</h2>
            <img
              src={item.badge}
              alt={item.alt}
              className={style.badge}
              loading="lazy"
            />
          </div>
          <div className={style.desc}>{item.desc}</div>
        </div>
      </div>
    </React.Fragment>
  ));

  const serviceList = serviceArr.map((service) => (
    <div
      className={`col-sm-6 col-md-6 col-lg-4 ${style.service_card_wrap}`}
      key={`service-${service.id}`}
    >
      <ServiceCard
        icon={service.icon}
        title={service.title}
        desc={service.desc}
      />
    </div>
  ));

  const projectList = projectsArr.map((project) => (
    <div
      className={`col-md-6 ${style.project_card_wrap}`}
      key={`project-${project.id}`}
    >
      <ProjectCard
        projectIcon={project.img}
        projectUrl={project.url}
        // desc={project.desc}
      />
    </div>
  ));

  return (
    <>
      <section className={style.home_sec1}>
        <Swiper>
          {/* <SwiperSlide>
            <div className={style.hero_image_wrapper}>
              <img
                width={1440}
                height={900}
                src={images.hero1.image}
                alt={images.hero1.alt}
                className={style.hero1}
              />
              <img
                width={1440}
                height={900}
                src={images.hero2.image}
                alt={images.hero2.alt}
                className={style.hero2}
              />
            </div>
            <div className={`my_container ${style.banner_container}`}>
              <div className={style.banner_wrapper}>
                <div className={style.sidebar}>
                  <div className={style.social_icons}>{socialList}</div>
                  <a href={whatsappURL} className={style.whatsapp_link}>
                    {contactNo}
                  </a>
                </div>

                <div className={style.banner_body}>
                  <h1 className={style.title}>
                    More &nbsp;than just&nbsp;&nbsp;a&nbsp;&nbsp;design agency
                  </h1>
                  <a
                    href={whatsappURL}
                    className="common_cta"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get a Demo
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
          <SwiperSlide>
            <div className={style.hero_image_wrapper}>
              <img
                width={1440}
                height={900}
                src={images.hero1.image}
                alt={images.hero1.alt}
                className={style.hero1}
              />
              <img
                width={1440}
                height={900}
                src={images.hero2.image}
                alt={images.hero2.alt}
                className={style.hero2}
              />
            </div>
            <div className={`my_container ${style.banner_container}`}>
              <div className={style.banner_wrapper}>
                <div className={style.sidebar}>
                  <div className={style.social_icons}>{socialList}</div>
                  <a href={whatsappURL} className={style.whatsapp_link}>
                    {contactNo}
                  </a>
                </div>

                <div className={style.banner_body}>
                  <h1 className={style.title}>
                    Your Growth
                    <br />
                    Partner
                  </h1>
                  <a
                    href={whatsappURL}
                    className="common_cta"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get a Demo
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>

      <section className={style.home_sec2}>
        <div className="my_container">
          <div className={`row ${style.attribute_row}`}>{attributeList}</div>
        </div>
      </section>

      <section className={style.home_sec3}>
        <div className="my_container">
          <div className={`row ${style.about_row}`}>
            <div className="col-lg-6">
              <div className={style.about_wrap}>
                <img
                  width={800}
                  height={800}
                  src={images.aboutus.image}
                  alt={images.aboutus.alt}
                  className={style.about_img}
                  loading="lazy"
                />
                {/* <FilledDot maxW="3em" maxH="3em" Padding="3em" /> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className={style.about_text}>
                <FilledDot
                  maxW="0.5em"
                  maxH="0.5em"
                  Padding="0.5em"
                  BgColor="#FFF"
                />
                {/* <p className={style.bread}>ABOUT US</p> */}
                <h2 className={style.title}>What makes us different?</h2>
                <p className={style.description}>
                  AsreSoft is a full range digital marketing agency, that can
                  help you grow your business. We deliver an individual and
                  tailored experience to each and every one of our clients.
                </p>
                <p className={`${style.description} ${style.description2}`}>
                  Our professional team will come up with a unique strategies
                  for your online presence, focusing on SEO, Content Marketing,
                  Social Media, or PPC, depending on your specific business
                  objectives. If you want clear, authentic and noticeable
                  results online with we can guarantee it happens. Rest assured
                  knowing that our based digital marketing professionals are
                  always ready to address your queries. Get ahead of the
                  competition and contact us today!
                </p>
                <a href={whatsappURL} className="common_cta">
                  Get a Demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={style.home_sec4}>
        <div className={style.image_wrapper}>
          {/* <img
              width={1280}
              height={560}
              src={images.showcase.image}
              alt={images.showcase.alt}
              className={style.bg_img}
              loading="lazy"
            /> */}
          <video loop autoPlay muted className={style.bg_img}>
            <source src={showcasevideo} type="video/mp4" />
          </video>
          {/* <img
            width={365}
            height={560}
            src={images.showcaseOverlay.image}
            alt={images.showcaseOverlay.alt}
            className={style.showcase_overlay}
            loading="lazy"
          /> */}
        </div>
      </section>

      <section className={style.home_sec5}>
        <div className="my_container">
          <div className={style.text_container}>
            <FilledDot
              maxW="0.5em"
              maxH="0.5em"
              Padding="0.5em"
              BgColor="#FFF"
            />
            {/* <p className={style.bread}>WHAT WE'RE OFFERING</p> */}
            <h2 className={style.title}>
              Our <span className="orange_text">Services</span>
            </h2>
          </div>

          <div className={style.service_cards}>
            <div className="row">{serviceList}</div>
          </div>
        </div>
      </section>

      <section className={style.home_sec6}>
        <div className="my_container">
          <div className={style.text_container}>
            <h2 className={style.title}>Projects</h2>
          </div>
          <div className={style.projects_wrapper}>
            <div className="row">{projectList}</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Homepage;
