// homepage
export { default as XIcon } from "../images/Twitter.svg";
export { default as FacebookIcon } from "../images/Facebook.svg";
export { default as LinkedinIcon } from "../images/LinkedIn.svg";
export { default as InstagramIcon } from "../images/Instagram.svg";
export { default as showcasevideo } from "../images/homepage/showcase.mp4";
export { default as websiteDev } from "../images/homepage/website-dev.svg";
export { default as seoServices } from "../images/homepage/seo-services.svg";
export { default as SMM } from "../images/homepage/social-media-marketing.svg";
export { default as GAM } from "../images/homepage/google-ads-management.svg";
export { default as activearrow } from "../images/general/hamburger-arrow.svg";
export { default as downarrow } from "../images/general/Polygon-1.svg";
export { default as wctaarrow } from "../images/general/Polygon-3.svg";

export let images = {
  //LOGOS
  //LOGOS
  logotransparent: {
    image: require("./general/logos/AS-Transparent.png"),
    alt: "AsreSoft logo",
  },
  logoColor: {
    image: require("./general/logos/AS-T-Color.png"),
    alt: "AsreSoft logo",
  },
  //LOGOS
  //LOGOS
  hero1: {
    image: require("./homepage/hero-1.jpg"),
    alt: "hero 1",
  },
  hero2: {
    image: require("./homepage/hero-2.jpg"),
    alt: "hero 3",
  },
  attribute1: {
    image: require("./homepage/creative-agency.jpg"),
    alt: "creative agency",
  },
  attribute2: {
    image: require("./homepage/quality-work.jpg"),
    alt: "quality work",
  },
  attribute3: {
    image: require("./homepage/professional-team.jpg"),
    alt: "professional team",
  },
  attribute1Badge: {
    image: require("./homepage/creative-badge.png"),
    alt: "creative agency badge",
  },
  attribute2Badge: {
    image: require("./homepage/quality-badge.png"),
    alt: "quality work badge",
  },
  attribute3Badge: {
    image: require("./homepage/team-badge.png"),
    alt: "professional team badge",
  },
  aboutus: {
    image: require("./homepage/about-us.jpg"),
    alt: "About Us",
  },
  showcase: {
    image: require("./homepage/showcase.jpg"),
    alt: "Showcase",
  },
  showcaseOverlay: {
    image: require("./homepage/showcase-overlay.png"),
    alt: "Showcase Overlay",
  },
  aethumb: {
    image: require("./homepage/projects/AE-thumb.png"),
    alt: "Strainers Manufacturer",
  },
  cithumb: {
    image: require("./homepage/projects/ci-thumb.gif"),
    alt: "care infotech",
  },
};
