import React, { useEffect, useState } from "react";
import "./footer.scss";
import { images } from "../../images";
import { socialArr } from "../../data/home";
import { Link } from "react-router-dom";

const Footer = () => {
  const [date, setDate] = useState();

  const socialList = socialArr.map((item) => (
    <React.Fragment key={`footer-${item.id}`}>
      <a
        href={item.url}
        id={item.id}
        target={item.external === 1 ? "_blank" : ""}
        rel="noreferrer"
        className="social_link"
      >
        <img src={item.icon} alt="" className="social_icon" />
      </a>
    </React.Fragment>
  ));

  const getYear = () => setDate(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);

  return (
    <>
      <footer className="footer">
        <div className="my_container">
          <section className="footer_sec1">
            <div className="row">
              <div className="col-md-6">
                <Link className="logo-wrap">
                  <img
                    width={318}
                    height={61}
                    src={images.logotransparent.image}
                    alt={images.logotransparent.alt}
                    className="logo"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col-md-6">
                <div className="social_icons">{socialList}</div>
              </div>
            </div>
          </section>
          <section className="footer_sec2">
            <div className="row">
              <div className="col-md-7"></div>
              <div className="work-mail">
                <div className="bread">Let’s start working together</div>
                <a href="mailto:info@asresoft.com" className="email-id">
                  info@asresoft.com
                </a>
              </div>
            </div>
            <div className="col-md-5">
              <ul className="quick-links"></ul>
              <ul className="quick-links"></ul>
            </div>
          </section>
          <section className="footer_sec3">
            <p className="copyright">ASRESOFT &copy; Copyright {date} </p>
          </section>
        </div>
      </footer>
    </>
  );
};

export default Footer;
