import React from "react";
import { Route, Routes } from "react-router-dom";
import { homepageURL } from "./helpers/constant-words";
import Homepage from "./pages/Homepage/Homepage";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";

const Routing = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path={homepageURL} element={<Homepage />} />
      </Routes>
      <Footer />
    </>
  );
};
export default Routing;
