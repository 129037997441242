import React from "react";

const FilledDot = ({ maxW, maxH, Padding, BgColor = "#0080FE" }) => {
  return (
    <div
      className="filled_circle"
      style={{
        maxWidth: maxW,
        maxHeight: maxH,
        padding: Padding,
        background: BgColor,
      }}
    ></div>
  );
};

export default FilledDot;
