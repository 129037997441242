import React from "react";
import "./projectcard.scss";
import { whatsappURL } from "../../helpers/constant-words";

const ProjectCard = ({ projectIcon, projectUrl }) => {
  return (
    <div className="project_card">
      <img
        src={projectIcon}
        alt="project thumbnail"
        className="project_img"
        loading="lazy"
      />
      {/* <p className="desc">{desc}</p> */}
      <div className="cta_wrapper">
        <a
          href={projectUrl}
          className="common_cta"
          target="_blank"
          rel="noreferrer"
        >
          Visit Website
        </a>
        <a
          href={whatsappURL}
          className="common_cta"
          target="_blank"
          rel="noreferrer"
        >
          Get a Demo
        </a>
      </div>
    </div>
  );
};

export default ProjectCard;
