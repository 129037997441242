import React from "react";
import "./servicecard.scss";

const ServiceCard = ({ icon, title, title2, desc }) => {
  return (
    <div className="service_card">
      {/* <img
        width={96}
        height={96}
        src={icon}
        alt="service"
        className="icon"
        loading="lazy"
      /> */}
      <h2 className="title">{title}</h2>
      <p className="desc">{desc}</p>
    </div>
  );
};

export default ServiceCard;
