import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  XIcon,
  images,
  websiteDev,
} from "../images";

export const socialArr = [
  { id: 1, icon: XIcon, alt: "X Icon", url: "/", external: 1 },
  { id: 2, icon: FacebookIcon, alt: "Facebook Icon", url: "/", external: 1 },
  { id: 3, icon: LinkedinIcon, alt: "Linkedin Icon", url: "/", external: 1 },
  { id: 4, icon: InstagramIcon, alt: "Instagram Icon", url: "/", external: 1 },
];

export const attributeArr = [
  {
    id: 1,
    title: "Websites",
    bgImg: images.attribute1.image,
    badge: images.attribute1Badge.image,
    alt: images.attribute1Badge.alt,
    desc: "Unlock your online potential with stunning websites that captivate your audience. From sleek designs to seamless functionality, we create digital experiences that leave a lasting impression.",
  },
  {
    id: 2,
    title: "Leads",
    bgImg: images.attribute2.image,
    badge: images.attribute2Badge.image,
    alt: images.attribute2Badge.alt,
    desc: "Supercharge your sales funnel with high-quality leads that convert. Our data-driven approach generates qualified prospects, helping you grow your customer base and achieve remarkable business growth.",
  },
  {
    id: 3,
    title: "Traffic",
    bgImg: images.attribute3.image,
    badge: images.attribute3Badge.image,
    alt: images.attribute3Badge.alt,
    desc: "Drive targeted traffic to your website and increase your online visibility. Our proven strategies and optimization techniques ensure that your business gets the attention it deserves in the digital landscape.",
  },
];

export const serviceArr = [
  {
    id: 1,
    icon: websiteDev,
    title: "Website Development",
    desc: "Responsive and user-centric websites to elevate your online presence and business success.",
  },
  {
    id: 2,
    icon: websiteDev,
    title: "SEO Services",
    desc: "A robust online presence and improved search engine visibility for lasting impact.",
  },
  // {
  //   id: 3,
  //   icon: websiteDev,
  //   title: "Social Media Marketing",
  //   desc: "Lorem ipsum dolor sit amet consectetur. Sit lobortis.",
  // },
  {
    id: 4,
    icon: websiteDev,
    title: "Ads Management",
    desc: "Drive targeted traffic and maximize your online presence with Ads.",
  },
];

export const projectsArr = [
  {
    id: 1,
    img: images.aethumb.image,
    desc: "Responsive and user-centric websites to elevate your online presence and business success.",
    url: "http://www.strainermanufacturers.com/",
  },
  {
    id: 2,
    img: images.cithumb.image,
    desc: "A robust online presence and improved search engine visibility for lasting impact.",
    url: "https://www.careinfotech.com/",
  },
];
