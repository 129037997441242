import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./header.scss";
import HeaderNavItem from "./HeaderNavItem";
import { useWindowSize } from "react-use";
import { images } from "../../images";
const body = document.querySelector("body");

const navLinksArr = [
  {
    id: 1,
    type: "img",
    img: images.logoColor.image,
    mainLink: "/",
    class: "logo_img",
  },
  // {
  //   id: 2,
  //   type: "text",
  //   mainTitle: "About Us",
  //   mainLink: "/",
  // },
  // {
  //   id: 3,
  //   type: "text",
  //   mainTitle: "Contact Us",
  //   mainLink: "/",
  // },
];
const mobNavLinksArr = [
  // {
  //   id: 1,
  //   type: "text",
  //   mainTitle: "About Us",
  //   mainLink: "/",
  // },
  // {
  //   id: 2,
  //   type: "text",
  //   mainTitle: "Contact Us",
  //   mainLink: "/",
  // },
];
const HeaderNew = () => {
  const [navlinkIndex, setNavlinkIndex] = useState(null);
  const { width } = useWindowSize();
  const { pathname: locationPathname } = useLocation();
  const [navActiveIndex, setNavActiveIndex] = useState(null);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isBg, setIsbg] = useState(false);

  useEffect(() => {
    body.style.overflow = "auto";
    if (locationPathname === "/") {
      setIsbg(false);
    } else {
      setIsbg(true);
    }
    setIsMenuActive(false);
  }, [locationPathname]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  });

  const handleScroll = () => {
    if (window.location.pathname === "/")
      if (window.pageYOffset > 60) {
        setIsbg(true);
      } else {
        setIsbg(false);
      }
  };

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
    if (!isMenuActive) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
  };

  const handleNavIndex = (i) => {
    if (navActiveIndex === i) {
      setNavActiveIndex(null);
    } else {
      setNavActiveIndex(i);
    }
  };

  const navlinkList = navLinksArr.map((nav, i) => (
    <li key={navLinksArr[i].id}>
      {nav.type === "text" ? (
        <div
          onClick={() => setNavlinkIndex(i)}
          className={`nav_tab ${navlinkIndex === i ? "active" : ""} `}
        >
          {nav.linktype === "external" ? (
            <a
              className={nav.class}
              href={nav.mainLink}
              target="_blank"
              rel="noreferrer"
            >
              {nav.mainTitle}
            </a>
          ) : (
            <NavLink className={nav.class} to={nav.mainLink}>
              {nav.mainTitle}
            </NavLink>
          )}
        </div>
      ) : (
        <></>
      )}
    </li>
  ));

  return (
    <>
      <header className={`header_sec ${isBg ? "active" : null}`}>
        {width > 1080 ? (
          <>
            <div className="my_container">
              <div className="navlinks">
                <ul>
                  <li>
                    <div className="logo_wrapper">
                      <a href="/">
                        <img
                          className="logo_img"
                          src={`${
                            isBg
                              ? images.logoColor.image
                              : images.logotransparent.image
                          }`}
                          alt="atlas img"
                          onClick={scrollHandler}
                        />
                      </a>
                    </div>
                  </li>
                </ul>
                <ul>{navlinkList}</ul>
              </div>
            </div>
            <div className="heading_strip">WEB DESIGN, WEB DEVELOPMENT</div>
          </>
        ) : (
          <>
            <header className="header">
              <div className="my_container">
                <div className="logo_wrapper">
                  <Link className="logo_link" to="/">
                    <img
                      src={`${
                        isBg
                          ? images.logoColor.image
                          : images.logotransparent.image
                      }`}
                      alt="atlas logo"
                      className="atlas_logo"
                    />
                  </Link>
                </div>
              </div>
              <nav className="nav_links_wrapper">
                <div
                  className={`nav_line2_wrapper_flex_wrapper ${
                    isMenuActive ? "active" : ""
                  }`}
                >
                  <div className="logo_wrapper">
                    <Link className="logo_link" to="/">
                      <img
                        src={`${
                          isMenuActive
                            ? images.logoColor.image
                            : images.logotransparent.image
                        }`}
                        alt="atlas logo"
                        className="atlas_logo"
                      />
                    </Link>
                  </div>
                  <ul className="nav_line2_wrapper_flex">
                    {mobNavLinksArr.map((navData, i) => (
                      <HeaderNavItem
                        navData={navData}
                        key={parseInt(navData.id)}
                        arrIndex={i}
                        handleNavIndex={handleNavIndex}
                        navActiveIndex={navActiveIndex}
                      />
                    ))}
                  </ul>
                </div>
              </nav>

              {/* mobile UI start */}
              <div
                className={`hamburger_lines ${
                  isMenuActive ? "active" : "" || isBg ? "black" : ""
                }`}
                onClick={handleHamburgerClick}
              >
                <div className="line line1"></div>
                <div className="line line2"></div>
                <div className="line line3"></div>
              </div>
            </header>
            {isMenuActive ? (
              ""
            ) : (
              <div className="heading_strip">WEB DESIGN, WEB DEVELOPMENT</div>
            )}
          </>
        )}
      </header>
    </>
  );
};

export default HeaderNew;
